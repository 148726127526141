import * as basicLightbox from 'basiclightbox';
import 'lazysizes';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger) 

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Parallax, Mousewheel } from 'swiper/modules';

import * as PIXI from 'pixi.js';
import { PixiPlugin } from "gsap/PixiPlugin";
import { PixelateFilter } from 'pixi-filters';

import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';


import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
barba.use(barbaPrefetch);


import $ from 'jquery';

var body = document.body,
  html = document.documentElement;

var windowHeight = Math.max(body.scrollHeight, body.offsetHeight,
  html.clientHeight, html.scrollHeight, html.offsetHeight);

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

window.Swiper = Swiper;
window.Navigation = Navigation;
window.Pagination = Pagination;
window.Autoplay = Autoplay;

window.$ = $;
window.Alpine = Alpine
Alpine.plugin(collapse)

Alpine.start()

Alpine.store('showPage', true)
Alpine.store('showArchive', false)

$(window).on('resize', function(){  
  setTimeout(function(){
    $('.rub-image').each( function(){
        var offsetLeft = $(this).parent().offset().left;
        var newLeft = offsetLeft - $(this).width()/4; 
    
        if (newLeft < 0) {
            newLeft = 0;
        }
    
        // Check that the image right side is not outside the window
        console.log(newLeft + $(this).width(), $(this).width(), window.innerWidth);
        if ((newLeft + $(this).width()) > window.innerWidth) {
            newLeft = window.innerWidth - $(this).width();
        }
    
        $(this).css('left', newLeft);
    
    })
  }, 500)
});

$(window).trigger('resize');



barba.init({
  transitions: [{
    // sync: false,
    name: 'opacity-transition',
    leave(data) {

      return gsap.to(data.current.container, {
          opacity: 0,
          ease: "circ.inOut",
          duration: 0.3,
      });
    },

    afterLeave(data) {
     console.log(data.current.container, data.next.container)
    },

    beforeEnter(data) {
      window.scrollTo(0, 0);   
      Alpine.store('showPage', true)
      Alpine.store('showArchive', false)

      $(window).trigger('resize');
   },
    enter(data) {

      return gsap.from(data.next.container, {
        opacity: 0,
        ease: "circ.inOut",
          duration: 0.3,

      });

      
    },
  }]
});

